<template>
  <div class="content">
    <div class="top">
      <div class="flex-wrap flex-h-center">
        <div>
          <div class="position">{{ recruitData.position }}</div>
          <div class="wages">{{ recruitData.money }}</div>
          <div class="other">
            <span>{{
              recruitData.experience == '经验不限'
                ? recruitData.experience
                : recruitData.experience + '年'
            }}</span>
            <a-divider type="vertical" />
            <span>{{ recruitData.education }}</span>
            <a-divider type="vertical" />
            <span>{{ recruitData.nature }}</span>
            <a-divider type="vertical" />
            <span>招{{ recruitData.planCount }}人</span>
          </div>
        </div>
        <div class="flex-con" v-if="enterpriseOfficePicList.length > 0">
          <div class="swiper-box">
            <swiper
              :options="swiperOption"
              v-if="enterpriseOfficePicList.length > 2 && isKeep"
            >
              <swiper-slide
                v-for="(item, index) in enterpriseOfficePicList"
                :key="index"
              >
                <img :src="item" alt="" />
              </swiper-slide>
            </swiper>
            <div class="one-img" v-else>
              <div
                v-for="(item, index) in enterpriseOfficePicList"
                :key="index"
              >
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom flex-wrap">
      <div class="flex-con bottom-left">
        <div class="title">
          <div class="horn-title-style">职位描述</div>
        </div>
        <div class="describe">
          <div v-html="recruitData.discription"></div>
        </div>
      </div>
      <div class="flex-con bottom-right">
        <div class="title">
          <div class="horn-title-style">公司介绍</div>
        </div>
        <div class="specific">
          <div class="flex-wrap flex-h-center">
            <img
              :src="recruitData.enterprise.enterprisePic"
              alt=""
              width="120"
              height="100"
              v-show="recruitData.enterprise.enterprisePic"
            />
            <img
              src="@/assets/zwtp.png"
              alt=""
              width="120"
              height="100"
              v-show="!recruitData.enterprise.enterprisePic"
            />
            <div class="company-info">
              <div>{{ recruitData.enterpriseName }}</div>
              <div>
                {{
                  setDict('park_customer_trade', recruitData.enterprise.trade)
                    ? setDict(
                        'park_customer_trade',
                        recruitData.enterprise.trade
                      )
                    : '未填写'
                }}
                I
                {{
                  setDict(
                    'enterprise_financing_status',
                    recruitData.enterprise.financingStatus
                  )
                    ? setDict(
                        'enterprise_financing_status',
                        recruitData.enterprise.financingStatus
                      )
                    : '未填写'
                }}
                I
                {{
                  setDict(
                    'enterprise_staff_count',
                    recruitData.enterprise.staffCount
                  )
                    ? setDict(
                        'enterprise_staff_count',
                        recruitData.enterprise.staffCount
                      )
                    : '未填写'
                }}
              </div>
              <div>
                联系电话：{{ recruitData.phone ? recruitData.phone : '未填写' }}
              </div>
              <div>
                公司地址：{{
                  recruitData.enterprise.address
                    ? recruitData.enterprise.address
                    : '未填写'
                }}
              </div>
            </div>
          </div>
          <div class="welfare">
            <ul class="flex-wrap flex-wrap-wrap">
              <li v-for="(item, index) in welfareList" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="introduce">
            {{ recruitData.enterprise.discription }}
          </div>
        </div>
      </div>
    </div>
    <div class="back">
      <a-button type="primary" @click="$router.go(-1)"> 返回 </a-button>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { dictQueryAllDictItems } from '@/api/api'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      recruitData: {},
      dictData: {},
      welfareList: [],
      swiperOption: {
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        loopedSlides: 2,
        effect: 'coverflow',
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: '-6%',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 4,
          slideShadows: false,
        },
        allowTouchMove: false,
      },
      isKeep: false,
      enterpriseOfficePicList: [],
    }
  },
  created() {
    if (this.$route.params.data) {
      this.recruitData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      this.enterpriseOfficePicList =
        this.recruitData.enterprise.enterpriseOfficePic.split(',')
    }
    this.isKeep = true
    console.log(this.enterpriseOfficePicList)
    this.gitAllDict()
  },
  methods: {
    async gitAllDict() {
      try {
        const res = await dictQueryAllDictItems({})
        if (res.success) {
          this.dictData = res.result
          this.setWelfare()
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setDict(code, value) {
      if (this.dictData[code]) {
        let result = this.dictData[code].find((item) => {
          return item.value == value
        })
        if (result) {
          return result.text
        } else {
          return ''
        }
      }
    },
    setWelfare() {
      if (this.recruitData.enterprise.welfare) {
        let welfareKeyList = this.recruitData.enterprise.welfare.split(',')
        welfareKeyList.forEach((item) => {
          this.welfareList.push(this.setDict('enterprise_welfare', item))
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  .top {
    background: #f5faff;
    > div {
      width: 1200px;
      margin: 0 auto;
      padding: 100px 0;

      .position {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }

      .wages {
        font-size: 30px;
        color: #ff9a15;
        margin: 20px 0 10px 0;
      }

      .other {
        font-size: 16px;
        color: #333333;

        /deep/ .ant-divider,
        .ant-divider-vertical {
          width: 1px;
          height: 20px;
          background: #c3c3c3;
          margin: 0 15px;
        }
      }
    }
  }

  .bottom {
    width: 1200px;
    margin: 60px auto;

    .title {
      border-bottom: 1px solid #e5e5e5;
      .horn-title-style {
        width: 160px;
        height: 55px;
        line-height: 55px;
        background-color: #1285d6;
        position: relative;
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: rgb(18, 133, 214);
          top: 100%;
          left: 50%;
          position: absolute;
          margin-left: -12px;
        }
      }
    }

    .bottom-left {
      margin-right: 35px;

      .describe {
        padding: 50px 0;
      }
    }

    .bottom-right {
      margin-left: 35px;
      .specific {
        padding-top: 50px;
        .company-info {
          margin-left: 20px;
          > div {
            &:nth-child(1) {
              color: #1285d6;
            }
            &:nth-child(2),
            &:nth-child(3) {
              color: #999999;
              margin: 8px 0;
            }
            &:nth-child(4) {
              color: #999999;
            }
          }
        }

        .welfare {
          padding: 35px 0;
          ul {
            margin: 0;
            li {
              color: #1285d6;
              width: 100px;
              height: 38px;
              line-height: 38px;
              border: 1px solid #1285d6;
              border-radius: 19px;
              text-align: center;
              margin: 0 40px 20px 0;
            }
          }
        }

        .introduce {
          font-size: 12px;
          color: #333333;
          line-height: 2;
        }
      }
    }
  }
}
.back {
  text-align: center;
  padding: 30px 0;
}

.swiper-box {
  width: 700px;
  margin: 0 auto;
  height: 230px;
  .swiper-container {
    width: 620px;
    height: 230px;
  }

  img {
    width: 320px;
    height: 230px;
  }
}

.one-img {
  text-align: center;
  img {
    width: 320px;
    height: 230px;
  }
}
</style>
