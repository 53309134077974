<template>
  <div class="main-body">
    <div class="content">
      <div class="search">
        <a-input-search
          v-model="condition"
          placeholder="请输入职位名称或公司名称"
          enter-button="搜索"
          size="large"
          @search="onSearch"
        />
      </div>
      <div class="flex-wrap">
        <div class="content-left">
          <div class="title">
            <div class="horn-title-style">招聘信息</div>
          </div>
          <div class="position-list">
            <div class="example" v-if="loading">
              <a-spin size="large" />
            </div>
            <div
              class="flex-wrap flex-vertical flex-center not-img"
              v-else-if="this.positionList.length <= 0"
            >
              <img src="@/assets/zwsj.png" alt="" />
              <div>暂无数据</div>
            </div>
            <ul v-else-if="this.positionList.length > 0">
              <li
                class="flex-wrap flex-h-center flex-between"
                @click="openDetails(item)"
                v-for="(item, index) in positionList"
                :key="index"
              >
                <div>
                  <div class="position-name">{{ item.position }}</div>
                  <div class="flex-wrap flex-h-center">
                    <span class="salary">{{ item.money }}</span
                    ><span class="education"
                      >{{
                        item.experience == '经验不限'
                          ? item.experience
                          : item.experience + '年'
                      }}
                      I {{ item.education }}</span
                    >
                  </div>
                </div>
                <div class="flex-wrap flex-h-center flex-between">
                  <div>
                    <div class="company-name">{{ item.enterpriseName }}</div>
                    <div class="industry">
                      {{
                        setDict('park_customer_trade', item.enterprise.trade)
                          ? setDict(
                              'park_customer_trade',
                              item.enterprise.trade
                            )
                          : '未填写'
                      }}
                      I
                      {{
                        setDict(
                          'enterprise_financing_status',
                          item.enterprise.financingStatus
                        )
                          ? setDict(
                              'enterprise_financing_status',
                              item.enterprise.financingStatus
                            )
                          : '未填写'
                      }}
                      I
                      {{
                        setDict(
                          'enterprise_staff_count',
                          item.enterprise.staffCount
                        )
                          ? setDict(
                              'enterprise_staff_count',
                              item.enterprise.staffCount
                            )
                          : '未填写'
                      }}
                    </div>
                  </div>
                  <div class="company-img">
                    <img
                      :src="item.enterprise.enterpriseLogo"
                      alt=""
                      width="74"
                      height="74"
                      v-show="item.enterprise.enterpriseLogo"
                    />
                    <img
                      src="@/assets/zwtp.png"
                      alt=""
                      width="74"
                      height="74"
                      v-show="!item.enterprise.enterpriseLogo"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <div class="paging">
              <a-pagination
                v-model="page.current"
                :pageSize="page.pageSize"
                :total="page.total"
                hideOnSinglePage
                @change="changePage"
              />
            </div>
          </div>
        </div>
        <div class="content-right flex-con">
          <div class="title flex-wrap flex-h-center flex-between">
            <div class="horn-title-style">热门企业</div>
            <div
              class="more-btn"
              @click="
                $router.push({
                  name: 'Resources',
                  query: { tabIndex: 1 },
                })
              "
            >
              更多>>
            </div>
          </div>
          <div class="hot-position-list">
            <div class="example" v-if="hotLoading">
              <a-spin size="large" />
            </div>
            <ul class="flex-wrap flex-wrap-wrap" v-else>
              <li
                v-for="(item, index) in enterpriseList"
                :key="index"
                @click="openEnterpriseDetails(item)"
              >
                <img
                  :src="item.enterprisePic"
                  alt=""
                  width="186"
                  height="119"
                  v-show="item.enterprisePic"
                />
                <img
                  src="@/assets/zwtp.png"
                  alt=""
                  width="186"
                  height="119"
                  v-show="!item.enterprisePic"
                />
                <div class="name t-hide">{{ item.enterpriseName }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getRecruitList,
  dictQueryAllDictItems,
  yqmsEnterpriseList,
} from '@/api/api'

export default {
  components: {},
  data() {
    return {
      page: {
        pageSize: 9,
        current: 1,
        total: 0,
      },
      loading: false,
      positionList: [],
      dictData: {},
      hotLoading: false,
      enterpriseList: [],
      condition: '',
    }
  },
  activated() {
    this.getRecruitList()
    this.gitAllDict()
    this.getEnterpriseList()
  },
  created() {
    this.getRecruitList()
    this.gitAllDict()
    this.getEnterpriseList()
  },
  methods: {
    async getRecruitList() {
      this.loading = true
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
          condition: this.condition,
        }
        const res = await getRecruitList(params)
        if (res.success) {
          this.positionList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    changePage(page) {
      this.page.current = page
      this.getRecruitList()
    },
    onSearch() {
      this.getRecruitList()
    },
    async gitAllDict() {
      try {
        const res = await dictQueryAllDictItems({})
        if (res.success) {
          this.dictData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    setDict(code, value) {
      if (this.dictData[code]) {
        let result = this.dictData[code].find((item) => {
          return item.value == value
        })
        if (result) {
          return result.text
        } else {
          return ''
        }
      }
    },
    openDetails(item) {
      this.$router.push({
        name: 'RecruitDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(item))),
        },
      })
    },

    async getEnterpriseList() {
      this.hotLoading = true
      try {
        const params = {
          pageNo: 1,
          pageSize: 6,
        }
        const res = await yqmsEnterpriseList(params)
        if (res.success) {
          this.enterpriseList = res.result.records
          this.hotLoading = false
        } else {
          this.hotLoading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.hotLoading = false
        this.$message.error(err.message)
      }
    },

    openEnterpriseDetails(data) {
      this.$router.push({
        name: 'EnterpriseDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(data))),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  padding-bottom: 50px;
  .content {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 50px 0;

    .search {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 40px;

      /deep/ .ant-btn-lg {
        padding: 0 40px;
      }
    }

    .content-left {
      width: 737px;
      background: #fff;

      .title {
        border-bottom: 1px solid #e5e5e5;
      }

      .position-list {
        padding: 0 20px;
        li {
          border-bottom: 1px solid #e5e5e5;
          padding: 15px 0;
          cursor: pointer;
          .company-img {
            margin-left: 30px;
          }

          .position-name {
            margin-bottom: 10px;
          }

          .education {
            margin-left: 15px;
          }

          .company-name {
            margin-bottom: 15px;
          }
        }

        .paging {
          text-align: right;
          padding: 25px 0;
        }

        .example {
          text-align: center;
          padding: 200px 0;
        }

        .not-img {
          font-size: 18px;
          color: #9cb9fa;
          padding: 197px 0;
          > div {
            margin-top: 20px;
          }
        }
      }
    }

    .content-right {
      margin-left: 20px;
      background: #fff;

      .title {
        border-bottom: 1px solid #e5e5e5;
        padding-right: 25px;

        .more-btn {
          border: 1px solid #1285d6;
          font-size: 16px;
          font-weight: 400;
          color: #1285d6;
          padding: 0 7px;
          cursor: pointer;
        }
      }

      .hot-position-list {
        padding: 30px 10px;
        ul {
          margin: 0;
          li {
            cursor: pointer;
            width: 50%;
            padding: 0 10px;
            .name {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              margin-top: 15px;
              margin-bottom: 20px;
            }
          }
        }
      }

      .example {
        text-align: center;
        padding: 100px 0;
      }
    }

    .position-name {
      color: #666;
      margin-bottom: 10px;
    }

    .salary {
      font-size: 16px;
      color: #ff9a15;
    }
    .education {
      font-size: 12px;
      color: #999999;
    }

    .company-name {
      color: #1285d6;
    }

    .industry {
      font-size: 12px;
      color: #999999;
    }
  }
}

.horn-title-style {
  width: 160px;
  height: 55px;
  line-height: 55px;
  background-color: #1285d6;
  position: relative;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: rgb(18, 133, 214);
    top: 100%;
    left: 50%;
    position: absolute;
    margin-left: -12px;
  }
}
</style>
